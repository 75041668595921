<template>
<section class="section-narrow text-black">
    <div class="section-content">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-lg-10">
                    <h1>{{$t('accounts.heading')}}</h1>
                    <p>{{$t('accounts.subtitle')}}</p>

                    <div class="row mt-5">
                        <div class="col-12 col-md-8">
                            <account-list />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import AccountList from './Components/AccountList.vue'

export default {
    name: 'AccountSelectPage',
    components: {
        AccountList
    }
}
</script>
