<template>
    <div class="bg-white cyrrus-rounded shadow mb-4">
        <div class="p-4">
            <h3 class="mb-1">
                {{$t('accounts.listTitle')}}
            </h3>
        </div>
        <div class="pb-4">
            <div class="list-group border-left-0 border-right-0 rounded-0">
                <a href="javascript:void(0);"
                    v-for="item in accounts"
                    :key="item.account_id"
                    class="list-group-item list-group-item-action border-left-0 border-right-0 text-primary font-weight-bold"
                    @click.prevent="onSelectAccount(item)"
                >
                    {{item.client_description}}
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AccountsList',
    computed: {
        accounts () {
            return this.$user.accessList || []
        },
        selectedAccount: {
            get () {
                return this.$store.state.selectedAccount
            },
            set (account) {
                this.$store.commit('selectAccount', account)
            }
        }
    },
    methods: {
        onSelectAccount (account) {
            this.selectedAccount = account
            if (this.$router.currentRoute.name !== 'Rates') {
                this.$router.push({ name: 'Rates' })
            }
        }
    }
}
</script>
